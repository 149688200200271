import JSZip from "jszip";
import React from "react";
import { useState } from "react";

function Error({ message }) {
  if (message === "") {
    return "";
  }
  return <div className="alert alert-danger mt-5">{message}</div>;
}

export default function DownloadId() {
  const [input, setInput] =  useState(
    (window.location.pathname.startsWith("/users/")
      ? window.location.pathname.slice("/users/".length)
      : (
        window.location.pathname.startsWith("/u/")
          ? window.location.pathname.slice("/u/".length)
          : ""
        )
    )
  );
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [downloadStatus, setDownloadStatus] = useState("");

  function handleInputChange(e) {
    setInput(e.target.value);
  }

  async function handleClick(e) {
    e.preventDefault();
    setLoading(true);

    const username = input.toLowerCase();
    setDownloadStatus(`Fetching runs for ${username}\n`);
    fetch(
      `https://s3.amazonaws.com/splits.io-runid-to-s3filename/users/${username}.json`
    )
      .then((resp) => resp.json())
      .then(async (runIds) => {
        if (runIds === undefined) {
          setError("run IDs response was blank");
          setLoading(false);
          return;
        }

        setDownloadStatus(`Found ${runIds.length} runs`);

        const zip = new JSZip();
        const dir = zip.folder(`splitsio-${username}`);
        if (dir === null) {
          setError("Cannot build zip file. Please try again or with a different browser.")
          setLoading(false);
          return
        }
        const promises: Promise<any>[] = [];
        for (const num in runIds) {
          const id10 = runIds[num];
          const id36 = id10.toString(36);
          setDownloadStatus(`Preparing ${id36}`);
          promises.push(
            fetch(
              `https://s3.amazonaws.com/splits.io-runid-to-s3filename/${id10}`
            )
              .then((resp) => resp.text())
              .then((s3filename) => {
                if (s3filename === undefined) {
                  setError("s3 filename response was blank");
                  setLoading(false);
                  return;
                }
                return fetch(
                  `https://s3.amazonaws.com/splits.io/splits/${s3filename}`
                )
                  .then((resp) => resp.blob())
                  .then((splits) => {
                    if (splits === undefined) {
                      setError("splits file response was blank");
                      setLoading(false);
                      return;
                    }
                    dir.file(`${id36}.lss`, splits);
                    setDownloadStatus(`Finished preparing ${id36}`);
                  })
                  .catch((err) => {
                    setError(
                      `Cannot download splits and/or make zip file. Error code 3 (${err})`
                    );
                    setLoading(false);
                  });
              })
          );
        }
        setDownloadStatus(`Fetching ${runIds.length} runs`);
        await Promise.all(promises);
        setDownloadStatus(`Building zip file`);
        zip.generateAsync({ type: "blob" }).then((blob) => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `splitsio-${username}.zip`;
          document.body.appendChild(link); // Required for Firefox
          link.click();
          setLoading(false);
          setInput("");
          setDownloadStatus("Download complete");
        });
      })
      .catch((err) => {
        setError(
          `Splits not found. Are you sure you have the right username? Error code 4 (${err})`
        );
        setLoading(false);
      });
  }

  return (
    <div>
      <Error message={error} />
      <p style={{ marginTop: "1rem" }}>
        Or enter your username to download a zip file of all your splits:
      </p>
      <form onSubmit={handleClick}>
        <input
          autoFocus
          onChange={handleInputChange}
          placeholder="glacials"
          type="text"
          value={input}
        />
        <button disabled={loading} type="submit">
          {loading ? "..." : "Download"}
        </button>
        <br />
        <pre>{downloadStatus}</pre>
      </form>
    </div>
  );
}
