import React from "react";
import { useState } from "react";

function Error({ message }) {
  if (message === "") {
    return "";
  }
  return <div className="alert alert-danger">{message}</div>;
}

export default function DownloadId() {
  const [input, setInput] = useState(
    window.location.pathname === "/" || window.location.pathname.startsWith("/users/") || window.location.pathname.startsWith("/u/")
      ? ""
      : `https://splits.io${window.location.pathname}`
  );
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  function handleInputChange(e) {
    setInput(e.target.value);
  }

  async function handleClick(e) {
    e.preventDefault();
    setLoading(true);

    var i = input;
    if (i.length === 0) {
      setError("Please enter a URL.");
      setLoading(false);
      return;
    }
    if (i.substring(0, "https://".length) !== "https://") {
      i = `https://${i}`;
    }
    if (i.substring(0, "https://splits.io/".length) !== "https://splits.io/") {
      setError("Make sure your link starts with https://splits.io/");
      setLoading(false);
      return;
    }

    const u = new URL(i);
    if (u.pathname.startsWith("/users/") || u.pathname.startsWith("/u/")) {
      setError("To download all of your splits, please type your username in the second box.");
      setLoading(false);
      return
    }
    const id36 = u.pathname.substring(1);
    const id10 = parseInt(id36, 36);

    fetch(`https://s3.amazonaws.com/splits.io-runid-to-s3filename/${id10}`)
      .then((resp) => resp.text())
      .then((s3filename) => {
        if (s3filename === undefined) {
          setError("s3 filename response was blank");
          setLoading(false);
          return;
        }

        fetch(`https://s3.amazonaws.com/splits.io/splits/${s3filename}`)
          .then((resp) => resp.blob())
          .then(async (splits) => {
            if (splits === undefined) {
              setError("splits file response was blank");
              setLoading(false);
              return;
            }
            console.log(splits.text())
            if ((await splits.text()).includes("AccessDenied")) {
              setError("That file doesn't exist; please check your link. Error code 5")
              setLoading(false);
              return;
            }
            const link = document.createElement("a");
            link.href = URL.createObjectURL(splits);
            link.download = `${id36}.lss`;
            document.body.appendChild(link); // Required for Firefox
            link.click();
            setLoading(false);
            setInput("");
          })
          .catch((err) => {
            setError(
              `Splits not found. Are you sure you have the right link? Error code 1 (${err})`
            );
            setLoading(false);
          });
      })
      .catch((err) => {
        setError(
          `Splits not found. Are you sure you have the right link? Error code 2 (${err})`
        )
        setLoading(false)
      });
  }

  return (
    <div>
      <Error message={error} />
      <p style={{ marginTop: "1rem" }}>
        Enter a Splits.io URL to download splits:
      </p>
      <form onSubmit={handleClick}>
        <input
          autoFocus
          onChange={handleInputChange}
          placeholder="https://splits.io/gcb"
          type="text"
          value={input}
        />
        <button disabled={loading} type="submit">
          {loading ? "..." : "Download"}
        </button>
      </form>
    </div>
  );
}
